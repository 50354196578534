import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoApi } from '@web/shared/data-access/model';
import { ExternalApplicationViewModel } from '@web/web/applicant/domain/job/data-access';
import { NavBottomService } from '@web/web/applicant/shared/data-access';
import { JobrapidoService } from '@web/web/shared/data-access/jobrapido';
import { LanguageSwitchService } from '@web/web/shared/data-access/language';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';
import { videoLinkTransform } from '@web/web/shared/util/function';
import posthog from 'posthog-js';
import { take, tap } from 'rxjs';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare let fbq: any;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare let gtag: any;

@Component({
  selector: 'wh-external-application',
  templateUrl: './external-application.component.html',
  styleUrls: ['./external-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalApplicationComponent implements OnInit {
  @Input() public utm_campaign?: string;
  @Input() public utm_source?: string;
  @Input() public utm_content?: string;
  @Input() public utm_medium?: string;
  @Input() public utm_term?: string;
  @Input() public zapier?: boolean;
  @Input() public isAssessmentPassed?: boolean;
  @Input() public talent_solution?: boolean;
  @Input() public jr_cid?: string;

  public readonly videoLinkTransform = videoLinkTransform;

  private jobId?: string;

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    public readonly externalApplicationViewModel: ExternalApplicationViewModel,
    public readonly navBottomService: NavBottomService,
    private readonly utmLocalStorageService: UtmLocalStorageService,
    private readonly jobrapidoService: JobrapidoService,
    public readonly languageSwitchService: LanguageSwitchService,
    public readonly loadingViewModel: LoadingViewModel,
  ) {}

  public ngOnInit(): void {
    this.navBottomService.isHidden = true;
    this.setUTMProperties();

    // feedback mechanism for Jobrapido
    if (this.jr_cid) {
      this.jobrapidoService.clickId = this.jr_cid;
    }
    this.jobrapidoService.sendJobrapidoClickValidityEvent();

    this.activatedRoute.params
      .pipe(
        take(1),
        tap(params => {
          if (params['jobId']) {
            this.jobId = params['jobId'];
            this.externalApplicationViewModel.setJobId(params['jobId']);
            this.externalApplicationViewModel.getJobDetails();

            // Track event for PostHog
            posthog.capture('external job view', {
              jobId: this.jobId,
              utm_campaign: this.utm_campaign,
              utm_source: this.utm_source,
              utm_content: this.utm_content,
              utm_medium: this.utm_medium,
              utm_term: this.utm_term,
              zapier: this.zapier,
            });

            // Track event for Facebook Pixel
            try {
              if (fbq) {
                fbq('track', 'ViewContent', {
                  content_ids: this.jobId ? this.jobId : [],
                  content_type: 'product',
                  value: 1.0,
                  currency: 'EUR',
                });
              }
            } catch (e) {
              // check what's the best way to handle this case
              // eslint-disable-next-line no-console
              console.warn('fbq not defined');
            }
          }

          // automatically apply if zapier
          if (this.zapier) {
            this.externalApplicationViewModel.setZapier(this.zapier);
            this.externalApplicationViewModel.setIsAssessmentPassed(this.isAssessmentPassed);
            this.processApplication();
          }
        }),
      )
      .subscribe();
  }

  public processApplication(): void {
    try {
      if (fbq) {
        fbq('track', 'AddToCart', {
          content_ids: this.jobId ? [this.jobId] : [],
          content_type: 'product',
          value: 1.0,
          currency: 'EUR',
        });
      }
    } catch (e) {
      // check what's the best way to handle this case
      // eslint-disable-next-line no-console
      console.warn('fbq not defined');
    }

    if (gtag) {
      gtag('event', 'conversion', {
        send_to: 'AW-375648535/LICUCJuW0Y0ZEJfij7MB',
      });
    }

    // Track event for PostHog
    posthog.capture('start application', {
      jobId: this.jobId,
      utm_campaign: this.utm_campaign,
      utm_source: this.utm_source,
      utm_content: this.utm_content,
      utm_medium: this.utm_medium,
      utm_term: this.utm_term,
      zapier: this.zapier,
    });

    this.jobrapidoService.sendJobrapidoApplicationStartEvent();

    this.externalApplicationViewModel.createApplication(this.talent_solution);
  }

  private setUTMProperties(): void {
    this.utmLocalStorageService.updateUtmProperties(
      this.utm_campaign,
      this.utm_source,
      this.utm_content,
      this.utm_medium,
      this.utm_term,
    );

    // Set UTM parameters
    Object.entries(this.utmLocalStorageService.utmProperties).forEach(([utmKey, utmValue]) => {
      this.utmLocalStorageService.setUtmData(utmKey, utmValue ?? '');
    });
  }

  public switchLanguage(selectedLanguage: TranslocoApi.Locale): void {
    this.languageSwitchService.setSelectedLanguage(selectedLanguage);
  }
}
