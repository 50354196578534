export namespace CompensationApi {
  export enum Currency {
    EUR = 'EUR',
    CHF = 'CHF',
  }

  export function convertCurrencyToHumanReadableString(currency: Currency): string {
    switch (currency) {
      case CompensationApi.Currency.EUR:
        return '€';
      case CompensationApi.Currency.CHF:
        return 'CHF';
    }
  }

  export enum Interval {
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
  }

  export enum PayType {
    GROSS = 'GROSS',
    NET = 'NET',
  }

  export enum BonusType {
    PER_UNIT = 'PER_UNIT',
    PER_YEAR = 'PER_YEAR',
    OTHER = 'OTHER',
  }

  export enum CompensationType {
    SALARY = 'SALARY',
    SALARY_RANGE = 'SALARY_RANGE',
    BONUS = 'BONUS',
  }

  export function convertCompensationTypeToHumanReadableString(compensationType: CompensationType): string {
    switch (compensationType) {
      case CompensationApi.CompensationType.SALARY:
        return 'month';
      case CompensationApi.CompensationType.SALARY_RANGE:
        return 'range';
      case CompensationApi.CompensationType.BONUS:
        return 'bonus';
    }
  }

  export function convertCompensationTypeToHumanReadableStringV2(compensationType: CompensationType): string {
    switch (compensationType) {
      case CompensationApi.CompensationType.SALARY:
        return 'Salary';
      case CompensationApi.CompensationType.SALARY_RANGE:
        return 'Salary range';
      case CompensationApi.CompensationType.BONUS:
        return 'Bonus';
    }
  }

  export function convertCompensationTypeToTranslocoString(compensationType: CompensationType): string {
    switch (compensationType) {
      case CompensationApi.CompensationType.SALARY:
        return 'domain.profile.feature.salary-preference.compensation-type.salary';
      case CompensationApi.CompensationType.SALARY_RANGE:
        return 'domain.profile.feature.salary-preference.compensation-type.salary-range';
      case CompensationApi.CompensationType.BONUS:
        return 'domain.profile.feature.salary-preference.compensation-type.bonus';
    }
  }

  export function convertCompensationAmountToToTranslocoString(): string {
    return 'domain.profile.feature.salary-preference.amount-to';
  }

  export function convertCompensationAmountFromToTranslocoString(): string {
    return 'domain.profile.feature.salary-preference.amount-from';
  }

  export function convertCompensationIntervalToTranslocoString(interval: Interval | undefined): string {
    if (!interval) return '';
    switch (interval) {
      case CompensationApi.Interval.HOURLY:
        return 'domain.profile.feature.salary-preference.interval.hourly';
      case CompensationApi.Interval.MONTHLY:
        return 'domain.profile.feature.salary-preference.interval.monthly';
      case CompensationApi.Interval.YEARLY:
        return 'domain.profile.feature.salary-preference.interval.yearly';
    }
  }

  export function convertCompensationPayTypeToTranslocoString(payType: CompensationApi.PayType | undefined): string {
    if (!payType) return '';
    switch (payType) {
      case CompensationApi.PayType.GROSS:
        return 'domain.profile.feature.salary-preference.paytype.gross';
      case CompensationApi.PayType.NET:
        return 'domain.profile.feature.salary-preference.paytype.net';
    }
  }

  export function convertIntervalToHumanReadableString(interval: Interval): string {
    switch (interval) {
      case CompensationApi.Interval.HOURLY:
        return 'Per hour';
      case CompensationApi.Interval.MONTHLY:
        return 'Per month';
      case CompensationApi.Interval.YEARLY:
        return 'Per year';
    }
  }

  export function convertIntervalToGermanString(interval: Interval): string {
    switch (interval) {
      case CompensationApi.Interval.HOURLY:
        return 'Pro Stunde';
      case CompensationApi.Interval.MONTHLY:
        return 'Pro Monat';
      case CompensationApi.Interval.YEARLY:
        return 'Pro Jahr';
    }
  }

  export interface Compensation {
    id?: string;
    amountFrom: number;
    amountTo?: number;
    currency: Currency;
    payType?: PayType;
    interval: Interval;
    bonusType?: BonusType;
    compensationType: CompensationType;
  }

  export interface CompensationViewItem {
    amountFrom: number;
    amountTo?: number;
    currency: Currency;
    bonusType?: BonusType;
    compensationType: CompensationType;
    interval: Interval;
  }
}
